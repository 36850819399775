<template lang="pug">
    .main-wrapper.transacoes-listar
        header.main-wrapper-header
            .p-grid.p-align-center
                .p-col-12.ta-right
                    h1.text-header.text-secondary MedClub / Caixa / <b>Movimentos Caixa</b>

        Dialog.dialogVisualizar(v-if='selected' header='Visualizar transação' :visible.sync='dialogVisualizar' :modal='true' @hide='selected = null')
            p <b>Pagante:</b> {{ selected.nm_pagante }}
            p <b>Caixa:</b> {{ selected.nm_caixa }}
            p <b>Unidade:</b> {{ selected.nm_unidade_medclub }}
            p <b>Data:</b> {{ selected.dt_movimento_f }}
            p <b>Valor:</b> {{ formatPrice(selected.nr_valor) }}

        Panel.mb-2(header='Filtros' :toggleable='true' :collapsed='windowInnerWidth < 576')
            .p-grid.p-fluid.p-align-start

                .p-col-12.p-md-6
                    label.form-label Pagante:
                    .p-inputgroup
                        InputText( @keyup.enter.native='applyFilters()' v-model='filters.nm_pagante'
                            @keyup='checkEmptyField("nm_pagante")' )
                        Button(icon='jam jam-search' @click='applyFilters()')

                .p-col-12.p-md-3
                    label.form-label Caixa:
                    ProgressBar(v-if="waitingCaixas" mode="indeterminate")
                    MultiSelect(v-else v-model='filters.cd_caixa_list' :options='options.caixas' filter
                        dataKey='id' optionLabel='nm_caixa' optionValue='id' placeholder='Selecione Caixa...' @hide='applyFilters()' )

                .p-col-12.p-md-3
                    label.form-label Tipo de Caixa:
                    MultiSelect(v-model='filters.ie_tipo_caixa_list' :options='options.tipo_caixa' dataKey='value'
                        optionLabel='text' optionValue='value' placeholder='Selecione Tipo de Caixa...' @change='applyFilters()')
                
                .p-col-12.p-md-3
                    label.form-label Tipo de Movimento:
                    MultiSelect(v-model='filters.ie_tipo_movimento_list' :options='options.tipo_movimento' dataKey='value'
                        optionLabel='text' optionValue='value' placeholder='Selecione Tipo de Movimento...' @change='applyFilters()' filter)

                .p-col-12.p-md-3
                    label.form-label Forma de pagamento:
                    MultiSelect(v-model='filters.cd_forma_pagamento_list' :options='options.formas_pagamento'
                        optionLabel='text' optionValue='value' placeholder='Selecione Forma...' @change='applyFilters()' )

                .p-col-12.p-md-3
                    label.form-label Unidade:
                    ProgressBar(v-if="options.unidades.length == 0" mode="indeterminate")
                    .p-inputgroup(v-else)
                        Dropdown(
                            placeholder='Selecione Unidade...'
                            :options='options.unidades'
                            optionLabel='label'
                            optionValue='value'
                            @change='applyFilters()'
                            @input='getSetor()'
                            v-model='filters.cd_unidades_list'
                            filter
                        )

                .p-col-12.p-md-3
                    label.form-label Setor:
                    ProgressBar(v-if="loading.setor" mode="indeterminate")
                    .p-inputgroup(v-else)
                        Dropdown(
                            placeholder='Selecione Setor...'
                            :options='options.setores'
                            optionLabel='label'
                            optionValue='value'
                            @change='applyFilters()'
                            @input='() => { getSalas(true); this.filters.cd_salas_list = [] }'
                            v-model='filters.cd_setores_list'
                            :disabled="options.setores.length == 0"
                            v-tooltip.bottom="options.setores.length == 0 ? 'Informe a unidade antes' : ''"
                            filter
                        )

                .p-col-12.p-md-3
                    label.form-label Sala:
                    ProgressBar(v-if="loading.sala" mode="indeterminate")
                    .p-inputgroup(v-else)
                        MultiSelect(
                            placeholder='Selecione Sala...'
                            :options='options.salas'
                            optionLabel='label'
                            optionValue='value'
                            @change='applyFilters()'
                            v-model='filters.cd_salas_list'
                            :disabled="options.salas.length == 0"
                            v-tooltip.bottom="options.salas.length == 0 ? 'Informe o setor antes' : ''"
                            filter
                        )

                .p-col-6.p-md-3
                    label.form-label Data inicial:
                    .p-inputgroup
                        Calendar( v-model='filters.dt_inicio' dateFormat="dd/mm/yy" :locale="ptbr" :maxDate='filters.dt_fim'
                            :manualInput='false' :touchUI='windowInnerWidth < 576' @input='applyFilters()' )
                        Button.p-button-danger(icon='jam jam-rubber' v-tooltip.top="'Limpar'" @click="limparData('dt_inicio'); applyFilters()")

                .p-col-6.p-md-3
                    label.form-label Data final:
                    .p-inputgroup
                        Calendar( v-model='filters.dt_fim' dateFormat="dd/mm/yy" :locale="ptbr" :minDate='filters.dt_inicio'
                            :manualInput='false' :touchUI='windowInnerWidth < 576' @input='applyFilters()' )
                        Button.p-button-danger(icon='jam jam-rubber' v-tooltip.top="'Limpar'" @click="limparData('dt_fim'); applyFilters()")
                
                .p-col-12.p-md-3
                    label.form-label <br>
                    Button(label='Limpar filtros' icon='jam jam-rubber' @click='clearFilters()')
        .mb-1.selecionar-items-panel
            div
                InputSwitch.mr-2(v-model='mostrarSelecionados' :disabled='!selecionados.length')
                label.label-mostrar-selecionados Mostrar somente selecionados
            div
                a.link-limpar(v-if='selecionados.length > 0' @click='limparSelecao()') Limpar
                p.text-soma <b>Selecionados:</b> {{  selecionados ? selecionados.length : 0 }}
                p.text-soma <b>Soma:</b> {{ formatPrice(sum) }}
                .waitingImprimir(v-if='waitingImprimir')
                    ProgressSpinner(strokeWidth='2')
                Button(v-else label='Imprimir' icon='jam jam-printer' @click='imprimir()') Imprimir

        ProgressBar(v-if='waiting' mode="indeterminate")
        div(v-else-if='!mostrarSelecionados && list.length == 0')
            p.ta-center.text-secondary(style='margin-top: 40px;') Nenhum registro encontrado.
        div(v-else)

            DataView.dataview.my-2(:value="list" layout="grid")
                template(#grid="props")
                    .p-col-12.p-md-3(style="padding: .5em" :class="{ disabled: !props.data.ie_status }")
                        Panel.panel-list.ta-center(:header="props.data.nm_pagante" style='position: relative')
                            .ta-left
                                p <b>Caixa:</b> {{ props.data.nm_caixa }}
                                p(v-if='props.data.ie_tipo_movimento') <b>Tipo de Movimento:</b> {{ options.tipo_movimento.find(item => item.text == props.data.nm_movimento_caixa)?.text }}
                                p(v-if='props.data.nm_pagante') <b>Pagante:</b>
                                    span {{ props.data.nm_pagante }}&nbsp;
                                    span(v-if='props.data.nr_cpf_pagante') ({{ props.data.nr_cpf_pagante_f }})
                                p <b>Pagamento:</b> {{ props.data.nm_forma_pagamento }}
                                p <b>Data:</b> {{ props.data.dt_movimento_f }}
                                p <b>Valor:</b> {{ formatPrice(props.data.nr_valor) }}

            Panel.datatable(header='Lista movimento')
                Paginator.mb-1(:rows='paginadorDinamico.rows' :first='paginadorDinamico.first' :totalRecords='paginadorDinamico.total' @page="paginadorDinamico.onPage")
                DataTable(ref='datatableTransacoes' :value="listaDinamica"
                    selectionMode="multiple"
                    :metaKeySelection="false"
                    dataKey="id"
                    :rowClass="rowClass"
                )
                    Column(headerStyle='width: 5%;' bodyStyle='text-align: center')
                        template(#header='{data}')
                            Checkbox(v-if='mostrarSelecionados ? selecionados.length : list.length' v-model='selecionarTodos' binary)
                        template(#body='{data}')
                            Checkbox(v-model='selecionados' :value='data' :id='data.id' @click.stop)
                    Column(headerStyle='width: 15%;' field='nm_caixa' header='Caixa')
                        template(#body='props')
                            span {{ props.data.nm_caixa }} <br>
                            em(v-if='props.data.nm_sala' style='font-size: 10px') Unidade: {{ props.data.nm_unidade }} <br> Setor: {{ props.data.nm_setor }} <br> Sala: {{ props.data.nm_sala }} <br>
                    Column(headerStyle='width: 15%;' bodyStyle='text-align: center;' field='nm_movimento_caixa' header='Tipo de Movimento')
                        template(#body='props')
                            span(v-if='props.data.ie_tipo_movimento') {{ options.tipo_movimento.find(item => item.text == props.data.nm_movimento_caixa)?.text }}
                            span(v-else) {{ props.data.nm_movimento_caixa }}
                    Column(headerStyle='width: 30%;' header='Pagante')
                        template(#body='props')
                            span {{ props.data.nm_pagante }}&nbsp;
                    Column(headerStyle='width: 15%;' bodyStyle='text-align: center;' field='nm_forma_pagamento' header='Pagamento')
                    Column(headerStyle='width: 10%;' bodyStyle='text-align: center;' field='dt_movimento_f' header='Data')
                    Column(headerStyle='width: 10%;' bodyStyle='text-align: left;' header='Valor')
                        template(#body='props')
                            span {{ formatPrice(props.data.nr_valor) }} 

                Paginator.mb-1(:rows='paginadorDinamico.rows' :first='paginadorDinamico.first' :totalRecords='paginadorDinamico.total' @page="paginadorDinamico.onPage")

</template>

<style lang="scss">
.transacoes-listar {
    .dataview {
        @media all and (min-width: 577px) {
            display: none;
        }
    }
    .datatable {
        @media all and (max-width: 576px) {
            display: none;
        }
        .cell {
            padding: 16px 0;
            text-align: center;
            &.ex  {  background-color: #e4f8e1; }
            &.a  { background-color: #faf3dd; }
        }
    }
    .dialogVisualizar {
        width: 92%;
        max-width: 500px;
        p {
            margin: 4px 0;
        }
    }
    .link-limpar {
        text-decoration: underline;
        font-size: 12px;
        cursor: pointer;
        font-weight: 700;
        margin-right: 10px;
        display: inline-block;
    }
    .text-soma {
        margin-right: 10px;
        display: inline-block;
        background-color: #faf3dd;
        box-shadow: 0 1px 2px #ddd;
        padding: 6px 10px;
    }
    .waitingImprimir {
        .p-progress-spinner {
            width: 27px;
            height: auto;
        }
    }
    .selecionar-items-panel {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
}
</style>

<script>
import ProgressBar from 'primevue/progressbar'
import DataView from 'primevue/dataview'
import Panel from 'primevue/panel'
import Paginator from 'primevue/paginator'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import InputText from 'primevue/inputtext'
import Calendar from 'primevue/calendar'
import InputMask from 'primevue/inputmask'
import Button from 'primevue/button'
import Dialog from 'primevue/dialog'
import ProgressSpinner from 'primevue/progressspinner'
import Dropdown from 'primevue/dropdown'
import MultiSelect from 'primevue/multiselect'
import Tooltip from 'primevue/tooltip'
import Checkbox from 'primevue/checkbox'
import InputSwitch from 'primevue/inputswitch'

import moment from 'moment'
import {Caixas, UnidadesMedclub, DominioValor} from '../../middleware'
import { pCalendarLocale_ptbr } from './../../utils'
import wsConfigs from './../../middleware/configs'
import { saveAs } from 'file-saver'
import axios from "axios";
import _ from 'lodash'

export default {
    created () {
        this.getCaixas()
        this.getUnidades()
        this.getDominio()
        this.applyFilters()
    },
    components: { ProgressBar, DataView, Panel, Paginator, DataTable, InputMask, MultiSelect, InputSwitch,
        Column, Button, Dialog, ProgressSpinner, InputText, Calendar, Dropdown, Tooltip, Checkbox },
    directives: { tooltip: Tooltip },
    watch: {
        'selecionados': function(val) {
            if (!val.length)
                this.mostrarSelecionados = false
            else if (this.mostrarSelecionados)
                this.paginadorDinamico['total'] = val.length
        },
        mostrarSelecionados: function() {
            this.atualizaPaginacao()
        },
    },
    computed: {
        selecionarTodos:  {
            get() {
                return this.mostrarSelecionados || this.selecionados.length && _.every(this.list, (v) => _.find(this.selecionados, { id: v.id }))
            },
            set(val) {
                if(val)
                    this.selecionados = _.unionBy(this.list, this.selecionados, 'id')
                else
                    this.selecionados = this.mostrarSelecionados ? [] : _.xorBy(this.selecionados, this.list, 'id')
            }
        },
        sum: function() {
            if (! this.selecionados) return 0
            let sum = 0
            this.selecionados.forEach(item => { sum += item.nr_valor })
            return sum
        },
        listaDinamica: function() {
            if (this.mostrarSelecionados) {
                const reagrupa = _.groupBy(this.selecionados, (v) => _.toInteger((_.indexOf(this.selecionados, v))/this.paginadorDinamico.rows))
                return reagrupa[this.paginadorDinamico.page] 
            } else
                return this.list
        }
    },
    data () {
        return {
            list: [],
            ptbr: pCalendarLocale_ptbr,
            windowInnerWidth: window.innerWidth,
            waiting: false,
            waitingCaixas: false,
            waitingUnidades: false,
            waitingTipo: false,
            waitingImprimir: false,
            filters: {
                nm_pagante: '',
                nr_cpf: '',
                cd_caixa_list: [],
                cd_unidades_list: null,
                cd_setores_list: null,
                cd_salas_list: [],
                cd_forma_pagamento_list: [],
                ie_tipo_movimento_list: [],
                ie_tipo_caixa_list: [],
                dt_inicio: moment()._d,
                dt_fim: moment()._d
            },
            paginator: {
                page: this.$route.query.pg ? this.$route.query.pg : 1,
                per_page: wsConfigs.paginator_perPage,
                count: 0
            },
            paginadorDinamico: {
                rows: wsConfigs.paginator_perPage,
                first: 0,
                total: 0,
                page: this.$route.query.pg || 1,
                onPage: null
            },
            options: {
                tipo_caixa: [],
                tipo_movimento: [],
                caixas: [],
                unidades: [],
                setores: [],
                salas: [],
                formas_pagamento: [
                    { value: [2,5,6], text: 'Cartão' }, // 2: Cartão, 5: Débito, 6: Crédito
                    { value: [1], text: 'Dinheiro' },
                    { value: [4], text: 'Medclub Crédito (MEDCRED)' },
                    { value: [3], text: 'Pix / Transferência' }
                ]
            },
            loading: {
                setor: false,
                sala: false,
            },
            selected: null,
            mostrarSelecionados: false,
            selecionados: [],
            dialogVisualizar: false,
            cancelToken: null,
            params: {},
            getListDebounce: _.debounce(function (params, getFunction) {
                getFunction(params)
            }, 500, { leading: true }),
        }
    },
    methods: {
        getList (params) {
            if (this.cancelToken) this.cancelToken.cancel()
            this.cancelToken = axios.CancelToken.source()

            this.waiting = true
            return Caixas.findAllMovimentos(params, this.cancelToken.token).then(response => {
                if (!response) return
                if (response.status === 200) {
                    this.paginator.count = response.data.count
                    this.paginadorDinamico.total = response.data.count
                    response.data.results.forEach(transacao => {
                        transacao.dt_movimento_f = moment(transacao.dt_movimento).format('DD/MM/YYYY')
                        //if (transacao.nr_cpf_pagante) transacao.nr_cpf_pagante_f = transacao.nr_cpf_pagante.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
                    })
                    this.list = response.data.results
                }
                this.atualizaPaginacao()
                this.waiting = false
                this.cancelToken = null

                setTimeout(() => {
                    if (this.selected) {

                        let checked = false
                        this.selected.forEach(item => {
                            let el = document.querySelector(`._row_${ item.id }`)
                            if (el && !checked) {
                                checked = true
                                el.click()
                            }
                        })
                        if (checked) this.selected.pop()
                    }
                })

                return true
            })
        },
        getUnidades (){
                UnidadesMedclub.findAll().then(response=>{
                    if (response.status == 200) {
						this.options.unidades.push({label: "- Selecione -", value: null})
                        response.data.forEach(e => {
                            this.options.unidades.push({label: e.nm_unidade, value: e.id})
                        })
                    }
                })
            },
        getSetor (){
            this.options.setores = []
            this.options.salas = []
            this.filters.cd_setores_list = null
            this.filters.cd_salas_list = []
            if (this.filters.cd_unidades_list) {
                this.loading.setor = true
                UnidadesMedclub.findAllSetor({cd_unidade: this.filters.cd_unidades_list}).then(response=>{
                    if (response.status == 200) {
                        this.options.setores.push({label: "- Selecione -", value: null})
                        if (response.data.length == 0) this.$toast.info("Nenhum setor encontrado.", { duration: 3000 });
                        response.data.forEach(e => {
                            this.options.setores.push({label: e.nm_setor, value: e.id})
                        })
                    }
                })
                this.loading.setor = false
            }
        },
        getSalas (setor = false){
            this.options.salas = []
            if(setor && !this.filters.cd_setores_list) return
            this.loading.sala = true
            const params = {}
            if(setor)
                params ["cd_setor"] = this.filters.cd_setores_list
            UnidadesMedclub.findAllSala(params).then(response=>{
                if (response.data.length == 0) this.$toast.info("Nenhuma sala encontrada.", { duration: 3000 });
                if (response.status == 200) {
                    response.data.forEach(e => {
                        this.options.salas.push({label: e.nm_sala, value: e.id})
                    })
                }
            })
            this.loading.sala = false
        },
        getCaixas () {
            this.waitingCaixas = true
            Caixas.findAll({ie_supervisao: true, ie_movimento: true}).then(response => {
                this.waitingCaixas = false
                if (response.status === 200) this.options.caixas = response.data
                this.waiting = false
            })
        },
        getDominio(){ 
            this.waitingTipo = true;
            DominioValor.findAll({ds_mnemonico: ['TIPO_MOVIMENTO_CAIXA', 'TIPO_CAIXA']}).then((response) => {
                this.waitingTipo = false;
                if(response.status === 200) {
                    this.options.tipo_movimento = response.data['TIPO_MOVIMENTO_CAIXA'].valores.map(item => ({
                        text: item.ds_valor, 
                        value: item.ie_valor
                    }))
                    this.options.tipo_caixa = response.data['TIPO_CAIXA'].valores.map(item => ({
                        text: item.ds_valor, 
                        value: item.ie_valor
                    }))
                }
            })
		},
        clearFilters () {
            Object.keys(this.filters).forEach(k => {
                if ((['dt_inicio', 'dt_fim']).includes(k)) this.filters[k] = moment()._d
                else this.filters[k] = null
            })

            this.paginator.page = 1
            this.applyFilters()
        },
        checkEmptyField (field) {
            if (! this.filters[field].length) this.applyFilters()
        },
        applyFilters (page) {
            const p = page || 1
                
            this.paginadorDinamico.page = p
            this.paginator.page = p

            let params = { paginacao: true, page: this.paginadorDinamico.page, per_page: this.paginadorDinamico.rows }

            if (this.$route.query.pg != this.paginator.page) this.$router.replace( { query: { pg: this.paginator.page } } )
            Object.keys(this.filters).forEach((key) => {
                if (key.substr(0, 3) == 'dt_') {
                    if (this.filters[key]) params[key] = moment(this.filters[key]).format('YYYY-MM-DD')
                } else if (this.filters[key]) params[key] = this.filters[key]
            })
            // Fake loading quando os filtros forem os mesmos
            if (_.isEqual(this.params, params)) {
                if (!this.waiting) {
                    this.waiting = true
                    setTimeout(() => this.waiting = false, 300)
                }
            } else {
                this.params = Object.assign({}, params)
                this.waiting = true
                this.getListDebounce(params, this.getList)
            }
        },
        formatPrice (val) {
            return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(val)
        },
        onPage (ev) {
            this.paginadorDinamico.page = ev.page + 1
			this.applyFilters(this.paginadorDinamico.page)
        },
        visualizar (id) {
            this.$router.push(`/transacoes-financeiras/visualizar/${ id }/`)
        },
        rowClass (a) {
            return `_row_${ a.id }`
        },
        limparData(key) {
            this.filters[key] = moment()._d
            if (this.filters.dt_fim < this.filters.dt_inicio) {
                this.filters['dt_fim' !== key && 'dt_fim' || 'dt_inicio'] = moment()._d
            }
        },
        limparSelecao () {
            this.selecionados = []
        },
        imprimir () {
            let params = {}
            if (this.$route.query.pg != this.paginator.page) this.$router.replace( { query: { pg: this.paginator.page } } )
            Object.keys(this.filters).forEach((key) => {
                if (key.substr(0, 3) == 'dt_') {
                    if (this.filters[key]) params[key] = moment(this.filters[key]).format('YYYY-MM-DD')
                } else if (this.filters[key]) params[key] = this.filters[key]
            })
            params.export = 'pdf'
            this.waitingImprimir = true
            Caixas.findAllMovimentos(params).then(response => {
                this.waitingImprimir = false
                if (response.status == 200) {
                    let blob = new Blob([response.data], { type: 'application/pdf; charset=utf-8' })
                    saveAs(blob, `relatorio-${ moment().format('YYYYMMDD_HHmmss') }.pdf`)
                }
            })
        },
        atualizaPaginacao () {
            this.paginadorDinamico['page'] = this.mostrarSelecionados 
                                        ? 0 
                                        : this.paginator.page  || 1
            ;

            [	
                this.paginadorDinamico['first'],
                this.paginadorDinamico['total'],
                this.paginadorDinamico['onPage'],
            ] = this.mostrarSelecionados ? [
                0,
                this.selecionados.length,
                ({ page })  => {
                    this.paginadorDinamico.page = page;
                    this.paginadorDinamico.first = this.paginadorDinamico.page * this.paginadorDinamico.rows;
                },
            ] : [
                ((this.paginadorDinamico.page - 1) * this.paginadorDinamico.rows),
                this.paginator.count,
                this.onPage,
            ]
        }

    }
}
</script>
